import React, { useContext } from "react";
import { Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";
import BlogDataProvider, { BlogDataContext } from "../data/BlogDataContext";
import BlogContainer from "../components/container/BlogContainer";

const ITEMS_PER_PAGE = 4;

const BlogItems = ({ data, defaultImage, index }) => {
  const publishDate = new Date(data.first_publication_date);

  const previewImage = convertToBgImage(
    getImage(data.data.preview_image.localFile)
  );
  const bannerImage = convertToBgImage(
    getImage(data.data.banner_image.localFile)
  );
  const bgDefault = convertToBgImage(getImage(defaultImage));

  const bgImage = previewImage !== null ? previewImage : bannerImage;
  return (
    <div className="mb-10 w-full">
      {bgImage !== null ? (
        <BackgroundImage
          key={`image${index}`}
          {...bgImage}
          alt={
            data.data.banner_image.alt !== null
              ? data.data.banner_image.alt
              : "Banner Image"
          }
          className="w-full h-56 bg-contain bg-no-repeat bg-center mb-5"
        >
          &nbsp;
        </BackgroundImage>
      ) : (
        <BackgroundImage
          className="w-full h-56 bg-contain bg-no-repeat bg-center mb-5"
          {...bgDefault}
        >
          &nbsp;
        </BackgroundImage>
      )}
      <p className="text-xs text-gray3">
        {publishDate.toLocaleDateString("en-US")}
      </p>
      <h3 className="mb-3 font-semibold min-h-16">
        <Link className="button-action" to={`/blog/${data.uid}`}>
          {data.data.banner_title.text}
        </Link>
      </h3>
      <div className="h-24 overflow-hidden mb-5 p-0">
        {data.data.banner_preview_text.text}
      </div>
      <Link to={`/blog/${data.uid}`}>Read More</Link>
    </div>
  );
};

const CreatePages = () => {
  const { blogData, dispatch } = useContext(BlogDataContext) || {
    blogData: {},
    dispatch: null,
  };

  const pageClick = (page) => {
    dispatch({ type: "FILTER_PAGE", page, limit: ITEMS_PER_PAGE, blogData });
  };

  let pagesObj = [];
  for (let i = 1; i <= blogData.pages; i++) {
    pagesObj.push(
      <button
        key={`page${i}`}
        className={`px-3 py-1 focus:outline-none ${
          blogData.page === i
            ? "bg-actionBlue text-white"
            : "bg-gray2 text-black"
        }`}
        onClick={() => pageClick(i)}
      >
        {i}
      </button>
    );
  }

  return blogData.pages > 1 ? (
    <div className="flex flex-row justify-center space-x-2">{pagesObj}</div>
  ) : null;
};

const BlogContent = () => {
  const { blogData } = useContext(BlogDataContext) || {
    blogData: {},
    dispatch: null,
  };

  return (
    <>
      {blogData.articles?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 sm:gap-10">
          {blogData.filteredArticles?.map((item, index) => {
            return (
              <BlogItems
                data={item.node}
                defaultImage={blogData.nwpLogo}
                key={`CategoryItem${index}`}
                index={index}
              />
            );
          })}
        </div>
      ) : (
        <h1>No article(s) found.</h1>
      )}
    </>
  );
};

const BlogPage = () => {
  return (
    <BlogDataProvider>
      <div className="w-full max-w-full relative overflow-hidden">
        <BlogContainer title="Blog">
          <BlogContent />
          <CreatePages />
        </BlogContainer>
      </div>
    </BlogDataProvider>
  );
};

export default BlogPage;
